import React from 'react'
import "./Navbar.css"
import Sidebar from './Sidebar'
import logo from "../images/logo.jpeg"
import About from './About'
const Navbar = () => {
  return (<div>

    <div className='nav_main'>
      <div className='nav_logo'>
        <img src={logo} className='logo' />
      </div>
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
    
    </div>
  </div>

  )
}

export default Navbar