import React from 'react'
import "./BodySection.css"

const BodySection = () => {
  return (
    <div className='body_main'>
        
        <h1 className='body_head'>A <span style={{color:"#FF6B00"}}> GREAT</span>  PLACE OF <span style={{color:"#FF6B00"}}>SELF STUDY </span> </h1>

        <h2 className='  body_head_h2'>MORETHAN 2000+ STUDENTS LEARNED THROUGH SELF STUDY</h2>

        <br/>
        <br/>

        <button className='body_head_btn'>Become a Self - Learner</button>

        
    </div>
  )
}

export default BodySection