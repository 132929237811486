import React from 'react'
import Card from './Card'
import "./Franchise.css"

const datas = [
    {
        id: 1,
        name: "Oxford library patna",
        location_name: "Bal Vidya Mandir school musallahapur haat patna",
        mob: "6201420656,6206305981"
    },
    {
        id: 2,
        name: "Oxford library patna",
        location_name: "Ghandi chowk near punjab national bank  ",
        mob: " 6201420656,6206305981"
    },
    {
        id: 3,
        name: "Oxford Library Munger",
        location_name: "Near konark cinema road.puraniganj munger.",
        mob: "8051298761,7004495303"
    },
]
const Franchise = () => {
    return (
        <div className='franchise_head' >
            <h2 id='franchise'>My Franchise</h2>
            <br />
            <br />
            <div className='franchise_main'>
                {
                    datas.map((item) => {
                        return (
                            <Card key={item.id} {...item} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Franchise