import React from 'react'
import "./Contact.css"
import fb from "../images/fb.svg"
import yt from "../images/yt.svg"
import ins from "../images/instagram.svg"
const Contact = () => {
  return (
    <div className='contact_main'>
      <h2 id='contact'>Contact Us</h2>
      <br />
      <br />
      <br />
      <div className='contact_social'>
        <a href='https://www.facebook.com/113526634453997/posts/113530917786902/?substory_index=1&sfnsn=wiwspmo&mibextid=RUbZ1f'><img src={fb} alt='fb' /> </a>
        <a href='https://youtu.be/Ngt7N9OFATA'>   <img src={yt} alt='yt' /> </a>
        <a href='https://instagram.com/oxford_library_patna?igshid=OGQ2MjdiOTE='>  <img src={ins} alt='ins' /> </a>
      </div>
    </div>
  )
}

export default Contact