import React from 'react'
import "./About.css"
import founderLogo from "../images/krishna.jpeg"
import brother from "../images/brother.svg"
import balram from "../images/img4.jpeg"
const About = () => {
    return (
        <div className='about_main'>
            <h2 id='about'>About Founder</h2>
            <div className='about_founder'>
                <img src={founderLogo} className="founder_logo" alt='founder image' />
            </div>
            <br />
            <p className='about_story'>
                Founder of Oxford Library, Krishna Singh Rathore ( motivational teacher)
            </p>
            {/* <p className='about_story'>
                Entreprene Founder @Oxford Library ,त्यागी और बागी दोनों हूं
            </p> */}
            <br/>
            <br/>
            <br/>
            <div className='about_founder'>
                <img src={balram} className="founder_logo" alt='founder image' />
            </div>
            <p className='about_story_1'>
               Krishna Singh Rathore is the youngest brother of Balram Kumar (civil engineer) Co-founder of Oxford Library.
            </p>
            <p className='about_story_1'>
             Rathore being the face of the branch oxford library .Balram is one of the most vital parts behind the success of the brand .He takes care of all the internal and operational affairs at oxford library along with the other successfully running business . He manages the functionality of the company responsibility without learning anyone distressed.
            </p>

            <p className='about_story_1'>
               Krishna Singh Rathore was borm in a small village shivkund ,which is close to MUNGER ,he pursued scholling and graduated.
            </p>
            <br/>

            <div>
                <img src={brother} alt={"brother image"} className="about_img"/>

            </div>
        </div>
    )
}

export default About