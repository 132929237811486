import React from 'react'
import "./Card.css"
const Card = (props) => {
    return (
        <div className='card_main'>
            <h2 className='card_branch_no'>
                {props.id}
            </h2>
            <p>{props.name}</p>
            <p>{props.location_name}</p>
            <br /><p>{props.mob}</p>
        </div>
    )
}

export default Card