import React from 'react'
import "./News.css";
import bgYt from "../images/backgroundYt.png"
const News = () => {
    return (
        <div className='news_main'>
            <div className='news_img' >
                {/* <img src={bgYt} alt="bg yt" /> */}
                <div style={{ position: "absolute", top: "130px", display: "flex", alignItems: "center", justifyContent: "center", border: "", overflow: "hidden", right: "20px", zIndex: "5" }}>
                    <iframe width={"378rem"} height={"210px"} src="https://www.youtube.com/embed/Ngt7N9OFATA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen ></iframe>
                </div>

            </div>
        </div>
    )
}

export default News